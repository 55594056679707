import { useEffect, useRef, useState } from 'react'
import { isMobile } from 'react-device-detect';
import { SlMenu, SlMagnifierRemove, SlMagnifierAdd, SlArrowLeft, SlArrowRight } from "react-icons/sl";
import { useSwipeable } from 'react-swipeable';

import { ReactComponent as WorldMap } from './../assets/images/whole_world_05.svg';
import { swipingEnded, whileSwiping } from '../functions/swiping';

import PhotosGrid from './Photos/PhotosGrid';
import SwipingInfo from './SwipingInfo';


export const Main = () => {

    // selected continent/country
    const [activeContinent, setActiveContinent] = useState();
    const [activeCountry, setActiveCountry] = useState();
    const [allContinents, setAllContinents] = useState();
    const [allCountries, setAllCountries] = useState();

    // blur-state
    const [isBlurred, setIsBlurred] = useState(false);

    // burger menu
    const [sideMenuActive, setSideMenuActive] = useState(false);

    // container of world-map
    const worldMapContainerRef = useRef();

    // svg of world map
    const worldSvgRef = useRef();

    // photogrid ref
    const photoGridRef = useRef();

    // var for touch device
    const isTouchDevice = 'ontouchstart' in window;


    // init
    useEffect(() => {
        // all countries
        const allCountriesCopy = [];
        // all continents
        const allContinentsCopy = [];
        allContinentsCopy.push(worldSvgRef.current.getElementById('Europa'));
        allContinentsCopy.push(worldSvgRef.current.getElementById('Ozeanien'));
        allContinentsCopy.push(worldSvgRef.current.getElementById('Amerika'));

        // index for countries
        let countryIndex = 0;

        // run through continents
        for (let i = 0; i < allContinentsCopy.length; i++) {
            const thisContinent = allContinentsCopy[i];

            // set class
            thisContinent.classList.add('continent');
            // set active attribute
            thisContinent.setAttribute('active', 'false');
            // set onclick
            thisContinent.addEventListener('click', () => setActiveContinent(i))


            // get countries of this continent
            const thisContinentsCountries = thisContinent.childNodes;

            // run through countries of this continent
            for (let j = thisContinentsCountries.length - 1; j >= 0; j--) {
                const thisCountry = thisContinentsCountries[j];
                const thisCountryIndex = countryIndex++;

                // set continent index
                thisCountry.setAttribute('countryIndex', thisCountryIndex);

                // set continent index
                thisCountry.setAttribute('continentIndex', i);

                // set class country
                thisCountry.classList.add('country');

                // set active attribute
                thisCountry.setAttribute('active', 'false');

                // set onclick
                thisCountry.addEventListener('click', () => setActiveCountry(thisCountryIndex));

                // push to country array
                allCountriesCopy.push(thisCountry);
            }
        }

        // set state
        setAllCountries(allCountriesCopy);
        setAllContinents(allContinentsCopy);

        // initial active country is index 0
        setActiveCountry(0);
        // if on mobile zoom in to continent
        // if (isMobile) setActiveContinent(0)
    }, []);


    // country on change
    useEffect(() => {
        if (allCountries) {
            // remove active class from all countries
            for (let i = 0; i < allCountries.length; i++) {
                allCountries[i].setAttribute('active', 'false');
            }
            // add active class
            if (activeCountry !== undefined) allCountries[activeCountry].setAttribute('active', 'true');
        }
    }, [activeCountry])

    // continent on change
    useEffect(() => {
        if (allContinents) {
            // remove active class from all continents
            for (let i = 0; i < allContinents.length; i++) {
                allContinents[i].setAttribute('active', 'false');
            }
            // add active class
            if (activeContinent !== undefined) allContinents[activeContinent].setAttribute('active', 'true');
        }
    }, [activeContinent])


    // prev country
    const previousCountry = () => {
        const newIndex = (activeCountry - 1 >= 0) ? activeCountry - 1 : allCountries.length - 1;
        setActiveContinent(parseInt(allCountries[newIndex].getAttribute('continentIndex')));
        setActiveCountry(newIndex);
    }
    // next country
    const nextCountry = () => {
        const newIndex = (activeCountry + 1 < allCountries.length) ? activeCountry + 1 : 0;
        setActiveContinent(parseInt(allCountries[newIndex].getAttribute('continentIndex')));
        setActiveCountry(newIndex);
    }

    // prev continent
    const previousContinent = () => {
        const newIndex = (activeContinent - 1 >= 0) ? activeContinent - 1 : allContinents.length - 1;
        setActiveContinent(newIndex);
    }
    // next continent
    const nextContinent = () => {
        const newIndex = (activeContinent + 1 < allContinents.length) ? (activeContinent + 1) : 0;
        setActiveContinent(newIndex);
    }


    // swipe photo-container
    const swipeCountriesHandler = useSwipeable({
        onSwiping: isBlurred ? false : (e) => whileSwiping(e, photoGridRef),
        onSwiped: isBlurred ? false : (e) => swipingEnded(e, photoGridRef, nextCountry, previousCountry),
    });


    // swipe map-container
    const swipeContinentHandler = useSwipeable({
        onSwiping: isBlurred ? false : (e) => whileSwiping(e, worldMapContainerRef),
        onSwiped: isBlurred ? false : (e) => swipingEnded(e, worldMapContainerRef, nextContinent, previousContinent),
    });



    return (
        <>
            <header className={isBlurred ? 'blur' : ''}>
                <h1>BASTIAN BRUNSCH</h1>
                {/* side menu */}
                <section id="side-menu" active={sideMenuActive ? 'true' : 'false'}>
                    <div id="close-burger" onClick={() => setSideMenuActive(false)}>
                        <SlMenu />
                    </div>
                    <ul className='no-scrollbar'>
                        {allCountries ?
                            [...allCountries].sort((a, b) => (a.id > b.id) ? 1 : ((b.id > a.id) ? -1 : 0)).map((elem, index) => {
                                const countryIndex = parseInt(elem.getAttribute('countryIndex'));
                                const continentIndex = parseInt(elem.getAttribute('continentIndex'));
                                return (
                                    <li key={`country-list-key-${index}`} active={countryIndex === activeCountry ? 'true' : 'false'} onClick={() => { setActiveCountry(countryIndex); setSideMenuActive(false); setActiveContinent(continentIndex) }}>
                                        {elem.id.replaceAll('Oe', 'Ö').replaceAll('Malaysia', 'Malaysia/Singapur')}
                                    </li>
                                )
                            })
                            :
                            <></>
                        }
                    </ul>
                </section>

            </header>

            <main>

                {/* swipe info */}
                {isTouchDevice ? <SwipingInfo /> : <></>}


                {/* world-map */}
                <div id="map-container" ref={worldMapContainerRef} className={(allContinents && activeContinent !== undefined ? allContinents[activeContinent].id : '') + (isBlurred ? ' blur' : '')}>
                    <div {...swipeContinentHandler}>
                        {/* svg here */}
                        <WorldMap ref={worldSvgRef} />

                        {/* buttons top right */}
                        <section className="top-btn-container">
                            {/* menu-icon */}
                            <SlMenu onClick={() => setSideMenuActive(true)} />
                            {/* zoom-out */}
                            {activeContinent !== undefined ?
                                <SlMagnifierRemove onClick={() => setActiveContinent()} />
                                :
                                <SlMagnifierAdd onClick={() => setActiveContinent(parseInt(allCountries[activeCountry].getAttribute('continentIndex')))} />
                            }
                        </section>
                    </div>
                </div>


                {/* photos-container */}
                {allCountries && activeCountry !== undefined ?
                    <section id="photos-container"  {...swipeCountriesHandler}>

                        {/* country navigation */}
                        <section id="map-nav-container" className={isBlurred ? 'blur' : ''}>
                            <SlArrowLeft onClick={previousCountry} />
                            <span className='countries-list' style={{ left: `-${activeCountry * 100}%` }}>
                                {allCountries.map((elem, i) => (
                                    <h3 key={`country-list-key-${i}`}>{elem.id.replaceAll('Oe', 'Ö').replaceAll('Malaysia', 'Malaysia/Singapur')}</h3>
                                ))}
                            </span>
                            <SlArrowRight onClick={nextCountry} />
                        </section>

                        {/* photo-grid */}
                        {allCountries && activeCountry !== undefined ? <PhotosGrid photoGridRef={photoGridRef} selectedCountry={allCountries[activeCountry]} isBlurred={isBlurred} setIsBlurred={setIsBlurred} /> : <></>}
                    </section>
                    :
                    <></>
                }

            </main>

            <footer className={isBlurred ? 'blur' : ''}>
                <ul>
                    <li>Bastian Brunsch</li>
                    <li><h2>Fotografie</h2> und <h2>Softwareentwicklung</h2></li>
                    <br />
                    <li><h3 style={{ display: 'none' }}>Kontakt</h3><a href="mailto:b.brunsch@bastianbrunsch.de">b.brunsch@bastianbrunsch.de</a></li>
                </ul>
            </footer>
        </>
    )
}

export default Main
import React from 'react';
import ReactDOM from 'react-dom';

import './assets/css/index.css';
import './assets/css/sideMenu.css';
import './assets/css/worldmap.css';
import './assets/css/photo-container.css';
import './assets/css/responsive.css';
import './assets/css/swiping-info.css';

import Main from './components/Main'

ReactDOM.render(
  <React.StrictMode>
    <Main />
  </React.StrictMode>,
  document.getElementById('root')
);
import { SlClose, SlArrowRight, SlArrowLeft } from "react-icons/sl";
import { useSwipeable } from 'react-swipeable';
import { useEffect, useRef, useState } from "react";

import Loader from "../Loader";
import { swipingEnded, whileSwiping } from "../../functions/swiping";

const Fullscreen = ({ sourceArray, activeSourceIndex, setActiveSourceIndex }) => {

    // photo-ref
    const photoRef = useRef();

    // image is loading
    const [imageIsLoading, setImageIsLoading] = useState(true);

    // on source-update
    useEffect(() => {
        // source changed - image is loading
        setImageIsLoading(true);

        // add keydown listener on mount
        window.addEventListener('keydown', keyPressed);

        // remove keydown listener on unmount
        return () => window.removeEventListener('keydown', keyPressed);
    }, [activeSourceIndex])

    // next image
    const nextImage = (e) => {
        if (e && e.stopPropagation) e.stopPropagation();
        const newIndex = activeSourceIndex + 1 < sourceArray.length ? activeSourceIndex + 1 : 0;
        setActiveSourceIndex(newIndex);
    }
    // previous image
    const prevImage = (e) => {
        if (e && e.stopPropagation) e.stopPropagation();
        const newIndex = activeSourceIndex - 1 >= 0 ? activeSourceIndex - 1 : sourceArray.length - 1;
        setActiveSourceIndex(newIndex);
    }


    // swipe handlers
    const handlers = useSwipeable({
        preventScrollOnSwipe: true,
        onSwiping: (e) => whileSwiping(e, photoRef, true),
        onSwiped: (e) => swipingEnded(e, photoRef, nextImage, prevImage, true, () => setActiveSourceIndex())
    });


    // if key is pressed
    const keyPressed = (e) => {
        const key = e.key;
        if (key === "ArrowLeft") prevImage();
        else if (key === "ArrowRight") nextImage();
        else if (key === "Escape") setActiveSourceIndex();
    }

    return (
        <div id='fullscreen-photo-preview' {...handlers} className='prevent-select' onClick={() => setActiveSourceIndex()}>

            {/* image-container */}
            <div className="image-container swipe-container">

                {/* left-btn */}
                <div className="btn-left" onClick={prevImage}><SlArrowLeft /></div>

                {/* loader-overlay */}
                {imageIsLoading ? <div className="loading-image"><Loader digital={false} large={true} color="var(--myRed)" /></div> : <></>}

                {/* image */}
                <img ref={photoRef} onLoad={() => setImageIsLoading(false)} src={sourceArray[activeSourceIndex].large} onClick={(e) => e.stopPropagation()} alt={sourceArray[activeSourceIndex].name} />

                {/* btn-right */}
                <div className="btn-right" onClick={nextImage}><SlArrowRight /></div>

            </div>

            {/* close */}
            <div className="top-btn-container">
                <SlClose />
            </div>
        </div>
    )
}

export default Fullscreen
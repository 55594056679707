import { Digital, Spinner } from "react-activity";
import "react-activity/dist/library.css";

const Loader = ({ color = 'gray', digital = true, large = false }) => (
    <div style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        margin: '2rem 0',
        color: color
    }}>
        {digital ?
            <Digital size={large ? 25 : undefined} />
            :
            <Spinner size={large ? 25 : undefined} />
        }
    </div >
)

export default Loader;
// on swiping
const whileSwiping = (e, referenceElem, upDirectionPossible = false) => {
    if (e.dir === 'Left' || e.dir === 'Right') {
        referenceElem.current.style.left = `${e.deltaX * 0.5}px`;
        referenceElem.current.style.transition = 'none';
    }
    else if (upDirectionPossible && e.dir === 'Up') {
        referenceElem.current.style.top = `${e.deltaY}px`;
        referenceElem.current.style.transition = 'none';
    }
}

// on swiping end
const swipingEnded = (e, reference, nextFunc, prevFunc, upDirectionPossible = false, upFunc) => {
    if (e.dir === 'Left' || e.dir === 'Right') {
        if (e.dir === 'Left') nextFunc();
        else if (e.dir === 'Right') prevFunc();
        reference.current.style.left = '0';
        reference.current.style.transition = 'all 1s';
    }
    else if (upDirectionPossible && e.dir === 'Up') upFunc();
}

export { whileSwiping, swipingEnded }
import { useState, useEffect, memo } from 'react'
import { PiSmileySadDuotone } from "react-icons/pi";

import Loader from '../Loader';
import Fullscreen from './Fullscreen';


const countryInfo = {
    Deutschland: { amount: 35 },
    Frankreich: { amount: 15 },
    Spanien: { amount: 42 },
    Portugal: { amount: 0 },
    Italien: { amount: 48 },
    Niederlande: { amount: 21 },
    Tschechien: { amount: 16 },
    Oesterreich: { amount: 34 },
    Slovenien: { amount: 6 },
    Kroatien: { amount: 24 },
    Ungarn: { amount: 9 },
    USA: { amount: 33 },
    Mexiko: { amount: 0 },
    Belize: { amount: 0 },
    Vietnam: { amount: 36 },
    Philippinen: { amount: 21 },
    Malaysia: { amount: 36 },
    Brunei: { amount: 9 },
    Indonesien: { amount: 36 },
    Australien: { amount: 116 }
}


// single photo in grid
const SinglePhoto = ({ source, name }) => {
    const [loaded, setLoaded] = useState(false);
    return (
        <>
            <img onLoad={() => setLoaded(true)} src={source} alt={name} />
            {!loaded ? <span className='loading-overlay'><Loader /></span> : <></>}
        </>
    )
}


// PhotosGrid
const PhotosGrid = ({ photoGridRef, selectedCountry, isBlurred, setIsBlurred }) => {

    const [initialized, setInitialized] = useState(false);
    const [sourceArray, setSourceArray] = useState();
    const [activeSourceIndex, setActiveSourceIndex] = useState();


    // on selectedCountry-change
    useEffect(() => {
        setInitialized(false);
        // set array of image-sources
        const arr = [];
        for (let i = 1; i <= countryInfo[selectedCountry.id].amount; i++) {
            arr.push({
                small: `${process.env.PUBLIC_URL}/countries/${selectedCountry.id}/small/photo-${i}.jpg`,
                large: `${process.env.PUBLIC_URL}/countries/${selectedCountry.id}/full/photo-${i}.jpg`,
                name: `Foto ${selectedCountry.id} ${i}`
            });
        }
        setSourceArray(arr);
    }, [selectedCountry]);


    // when source array is loaded
    useEffect(() => {
        setInitialized(true);
    }, [sourceArray])


    // blur background if fullscreen is active
    useEffect(() => {
        setIsBlurred(activeSourceIndex !== undefined ? true : false);
    }, [activeSourceIndex])



    // map image-sources
    return (
        <div>
            {sourceArray && sourceArray.length === 0 ? <h4>Momentan noch keine Bilder verfügbar <PiSmileySadDuotone /></h4> : <></>}

            <div className={`photo-grid-container ${isBlurred ? 'blur' : ''}`} ref={photoGridRef}>
                {initialized && sourceArray ?
                    sourceArray.map((source, index) => (
                        <div className='photo-grid-item' key={`grid-photo-key-${index}`} onClick={() => setActiveSourceIndex(index)}>
                            <SinglePhoto source={source.small} name={source.name} />
                        </div>
                    ))
                    :
                    <Loader digital={false} color={'var(--myRed)'} />
                }
            </div>

            {initialized && sourceArray && sourceArray[activeSourceIndex] ?
                <Fullscreen activeSourceIndex={activeSourceIndex} setActiveSourceIndex={setActiveSourceIndex} sourceArray={sourceArray} />
                :
                <></>
            }
        </div>
    )
}

export default memo(PhotosGrid)
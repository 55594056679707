import { useEffect, useRef } from "react";
import { SlArrowRight, SlArrowLeft } from "react-icons/sl";

const SwipingInfo = ({ }) => {

    // html elem
    const container = useRef();

    // init
    useEffect(() => {

        // fade in timeout
        setTimeout(() => {
            container.current.style.display = 'flex';
            setTimeout(() => container.current.style.opacity = '1', 2000);
        }, 2000);

        // fadeout timeout
        setTimeout(fadeOut, 9000);
    }, [])

    // fade out on click
    const fadeOut = () => {
        container.current.style.opacity = '0';
        setTimeout(() => container.current.style.display = 'none', 1000);
    }

    return (
        <div className='swiping-info swipe-container' ref={container} onClick={fadeOut}>

            <p>Navigiere mit einem Swipe oder einem Klick durch verschiedene Länder/Kontinente</p>

            <div className="btn-left"><SlArrowLeft /></div>
            <div className="btn-right"><SlArrowRight /></div>
        </div>
    )
}

export default SwipingInfo